import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import man from "../../../../Component/images/sitting-man.png"
import arrow from "../../../../Component/icons/arrow.png"
import { ServicesList } from "../../../../Entities/Services/services-list";
import { useAppNavigation } from "../../../../hooks/use-app-navigation";
import { useServices } from "../../../../Entities/Services/model";
import { ServiceCard } from "../../../../Entities/Services/ui/service-card";

export const ServicesSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { navigateTo } = useAppNavigation();
    const { services } = useServices()

    return (
        // <Box
        //     sx={{
        //         backgroundColor: 'linear-gradient(180deg, #fff, #f6fdff)'
        //     }}
        //     class="section-with-background"
        // >
        //     <Box
        //         display="flex"
        //         flexDirection={isMobile ? "column" : "row"}
        //     >
        //         {!isMobile && <Box
        //             maxWidth={"40%"}
        //             height={'500px'}
        //             display="flex"
        //             alignSelf={'flex-end'}
        //         >
        //             <img
        //                 src={man}
        //                 alt="Hero"
        //                 style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
        //             />
        //         </Box>}
        //         <Box
        //             display={'flex'}
        //             flexDirection={'column'}
        //             textAlign={'left'}
        //             maxWidth={isMobile ? "100%" : "65%"}
        //             mb={isMobile ? "2rem" : "0"}
        //             marginTop={isMobile ? "3.5rem" : "0"}
        //             marginLeft={!isMobile ? '5rem' : undefined}
        //         >
        //             <Typography
        //                 variant={isMobile ? "h4" : "h2"}
        //                 fontWeight="bold"
        //                 gutterBottom
        //                 fontSize={isMobile ? '2rem' : '3.2rem'}
        //                 fontFamily="Rajdhani, sans-serif"
        //                 color={"#023E8A"}
        //                 textAlign={'left'}
        //                 maxWidth={!isMobile ? '65%' : undefined}
        //             >
        //                 Services <span style={{ color: '#0077B6' }}>that we</span> provide
        //             </Typography>
        //             <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'}>
        //                 <Typography
        //                     class="hero--description_text"
        //                     fontFamily="Livvic, sans-serif"
        //                     sx={{ color: "#000000" }}
        //                     textAlign={'left'}
        //                     fontSize={'16px'}
        //                     maxWidth={isMobile ? '70%' : '55%'}
        //                 >
        //                     Explore our comprehensive IT solutions tailored to drive your business success.
        //                     </Typography>
        //                 <Box onClick={() => navigateTo('services')} marginLeft={'auto'} alignSelf={'flex-end'} marginTop={isMobile ? '-1.2rem' : undefined}>
        //                     <img
        //                         src={arrow}
        //                         alt="arrow"
        //                     />
        //                 </Box>
        //             </Box>
        //             <Box width={'100%'}>
        //               <ServicesList maxNum={3}/>
        //             </Box>
        //         </Box>
        //     </Box>

        // </Box>
        <div className="service-section">
            <div className="blue-background-gradient-bottom">
                <div className="section-gap-bottom-130">
                    <div className="custom-container-fluid w-container">
                        <div className="service-grid-layout">
                            <div id="w-node-fb1defa7-73e6-2c60-b8b4-007faa4d6384-44adaf1d" className="service-gridbox-left">
                                <div className="service-section-image-box">
                                    <img
                                        src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63341042f7701e23656ea64e_service-user-image.png"
                                        loading="lazy"
                                        alt="Service Image"
                                    />
                                    <img
                                        src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6334116d79bae20119ca0ab8_service-dotted-pattern.png"
                                        loading="lazy"
                                        alt="Service Shape"
                                        className="service-section-dotted-shape"
                                    />
                                    <img
                                        src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6334116d79bae29598ca0ab7_service-text-quote-shape.png"
                                        loading="lazy"
                                        alt="Service Quote Image"
                                        className="service-section-quote-image"
                                    />
                                </div>
                            </div>
                            <div className="service-gridbox-right">
                                <div className="section-up-wrapper">
                                    <div className="section-block">
                                        <h2 className="section-title max-width-152">
                                            Services <span className="section-title-inner-style">that we</span> provide
                                        </h2>
                                        <div className="section-text max-width-370">
                                          Explore our comprehensive IT solutions tailored to drive your business success.
                                        </div>
                                    </div>
                                    <a href="/services" className="section-page-link w-inline-block">
                                        <img
                                            src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63390d03c6e89f1c3da0fc28_arrow-long-icon-right-blue.png"
                                            loading="lazy"
                                            alt="Arrow Right Icon"
                                        />
                                    </a>
                                </div>
                                <div className="service-collection-list-wrapper w-dyn-list">
                                    <div role="list" className="service-collection-list w-dyn-items">
                                        {
                                            services.slice(0, 3).map((service) => <ServiceCard service={service} key={service.id}/>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}