import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase.config';

const ArticlesContext = createContext();

export const useArticles = () => {
    return useContext(ArticlesContext);
};

export const ArticlesProvider = ({ children }) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching')
            const q = collection(db, "articles");
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const articles = [];
                querySnapshot.forEach((doc) => {
                    articles.push({ id: doc.id, ...doc.data() });
                });
                setArticles(articles);
            });

            return () => unsubscribe();
        };

        fetchData();
    }, []);

    const getArticleById = async (id) => {
        if (!id) {
            return null;
        }

        const cachedArticle = articles.find(article => article.id === id);
        if (cachedArticle) {
            return cachedArticle;
        }

        try {
            const docRef = doc(db, "articles", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return { id: id, ...docSnap.data() };
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    return (
        <ArticlesContext.Provider value={{ articles, getArticleById }}>
            {children}
        </ArticlesContext.Provider>
    );
};
