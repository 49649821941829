import React from "react";
import NavItem from "./NavItem";
import { useLocation } from 'react-router-dom';

export default function NavLinks(props) {
    const location = useLocation()
    const currentPath = location.pathname;

    const navLinks = [ 
        { title: "Home", link: "/" }, 
        { title: "Services", link: "services" }, 
        // { title: "Projects", link: "projects" }, 
        { title: "About Us", link: "about" },
        { title: "Blogs", link: undefined }, 
        { title: "Contact", link: "contact" },
    ]

    return (
        navLinks.map((item, index) => (
           <NavItem key={index} item={item} currentPath={currentPath}/>
        ))
    );
}