import React from "react";
import { Link } from 'react-router-dom';


export default function NavItem({ item, currentPath }) {
    const isActive = () => {
        if (item.link === "/" && currentPath === "/") {
            return true
        }

        return currentPath.startsWith("/" + item.link)
    }

    return (
        <Link to={item.link} className={`nav-link w-nav-link ${isActive() ? "w--current" : ""}`} aria-current={isActive() ? "page" : undefined}>{item.title}</Link>
    );
}