import { PageHeader } from "../../Component/shared/component/PageHeader";
import { Hero } from "./sections/hero";
import { ServicesSection } from "./sections/services";
import NewsLetter from "../../Component/shared/component/NewsLetter";
import { ServicesHelmet } from "./helmet";
import { useRef, useEffect } from "react";

export default function ServicesScreen() {
    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -10% 0px'
            }
        );

        sectionsRef.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            sectionsRef.current.forEach((section) => {
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);
    return (
        <div>
            <ServicesHelmet />
            <main className="main-wrapper">
            <div className="tsection" ref={(el) => sectionsRef.current[0] = el}>
                <PageHeader
                    title={'Our Services'}
                    description={`We offer a comprehensive range of innovative tech solutions designed to meet the diverse needs of businesses and individuals.`}
                    location={[
                        { name: "Home", path: "/" },
                        { name: "Services", path: "" }
                    ]}
                />
                <Hero />
            </div>
            <div className="tsection" ref={(el) => sectionsRef.current[1] = el}>
                <ServicesSection />
                <NewsLetter />
            </div>
            </main>
        </div>
    )
}