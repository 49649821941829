import React from 'react'
import { useServices } from '../../../../Entities/Services/model'


const Service = ({ isActive, service}) => {
    return (
        <div role="listitem" className="service-link-item w-dyn-item">
            <a href={`/services/${service.id}`}
                aria-current={isActive ? "page" : undefined}
                className={`service-link ${isActive ? "w--current" : ""}`}
            >
                {service.title}
            </a>
            <div className="service-link-separator"></div>
        </div>
    )
}

export default function ServicesList({ title }) {
    const { services } = useServices()
    return (
        <div className="service-top-menu-block">
            <div className="service-link-list-wrapper w-dyn-list">
                <div role="list" className="service-link-list w-dyn-items">
                    {
                        services.map((service) => (
                            <Service key={service.id} isActive={service.title === title} service={service}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
