import {
    Box,
    useMediaQuery,
    Typography,
    Button
} from "@mui/material";
import { PageHeader } from "../shared/component/PageHeader";
import NewsLetter from "../shared/component/NewsLetter";
import TextButton from "../shared/ui-lib/text-button";
import { useAppNavigation } from "../../hooks/use-app-navigation";
import notfound from "../images/404.svg"
import { Link } from "react-router-dom";

export default function NotFoundScreen() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { navigateTo } = useAppNavigation()

    return (
        <main className="main-wrapper">
            <PageHeader
                title={'Error 404'}
                description={'The page you are looking for does not exist.'}
                location={[
                    { name: "Home", path: "/" },
                    { name: "404", path: "" }
                ]}
            />
           <div className="error-section wf-section">
            <div className="section-gap-130">
                <div className="container w-container">
                    <div className="error-block">
                        <div className="sign-up-from-area">
                            <div className="section-block">
                                <h2 className="section-title max-width-442">
                                    Page not <span className="section-title-inner-style">Found</span>
                                </h2>
                                <p className="section-text">
                                Oops! The page you are looking for doesn't exist. It might have been moved or deleted. Navigate back to the homepage or use the menu to find what you need.
                                </p>
                            </div>
                            <Link to="/" className="primary-button margin-top-60">
                                Back to Home
                            </Link>
                        </div>
                        <div className="error-image-box">
                            <img 
                                src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6353bec17e62504305f66540_404-error.svg" 
                                loading="lazy" 
                                alt="404 Error Image" 
                                className="image-responsive"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <NewsLetter />
        </main>
    )
}