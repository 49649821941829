import {
    Box,
    useMediaQuery
} from "@mui/material";
import { PageHeader } from "../../Component/shared/component/PageHeader";
import { CompanyStats } from "../../Component/shared/component/CompanyStats";
import { WhyUsSection } from "../../Component/shared/component/why-softsavvy";
import NewsLetter from "../../Component/shared/component/NewsLetter";
import { AboutHelmet } from "./helmet";
import { useRef, useEffect } from "react";
import Hero from "./sections/hero";
import Mission from "./sections/mission";
import Values from "./sections/values";

export default function AboutScreen() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -10% 0px'
            }
        );

        sectionsRef.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            sectionsRef.current.forEach((section) => {
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    return (
        <div>
            <AboutHelmet />
            <div className="tsection" ref={(el) => sectionsRef.current[0] = el}>
                <PageHeader
                    title={'About Us'}
                    description={`Empowering Your Success Through Innovative Technology and Unparalleled Dedication to Service Excellence.`}
                    location={[
                        { name: "Home", path: "/" },
                        { name: "About", path: "" }
                    ]}
                />
                <Hero />
            </div>
            <div className="tsection" ref={(el) => sectionsRef.current[1] = el}>
                <Mission />
            </div>
            <div className="tsection" ref={(el) => sectionsRef.current[2] = el}>
               <Values />
                <Box
                    padding={!isMobile ? "5rem 10rem" : "3rem 1rem"}
                >
                    <Box marginTop={isMobile ? '0.5rem' : '5rem'}>
                        <CompanyStats />
                    </Box>
                </Box>
            </div>

            <div className="tsection" ref={(el) => sectionsRef.current[3] = el}>
                <Box margin={isMobile ? '0.5rem 0rem' : '5rem 0rem'} >
                    <WhyUsSection />
                </Box>
                <NewsLetter />
            </div>
        </div>
    )
}