import React from "react";
import { useMediaQuery } from "@mui/material";
import { useAppNavigation } from "../../../../hooks/use-app-navigation";
import { Link } from 'react-router-dom';

export const AboutSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { navigateTo } = useAppNavigation();

    return (
        <div className="eliminate-section">
        <div className="section-gap-130-eliminate">
          <div className="container w-container">
            <div className="eliminate-grid">
              <div id="w-node-bb981379-6d5b-d20f-880f-d0a5b9a4085c-44adaf1d" className="eliminate-gridbox-left">
                <div className="eliminate-image-box">
                  <img
                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6332ca4998f5bbe665deffe0_eliminate-image.png"
                    loading="lazy"
                    alt="Eliminate Image"
                    className="eliminate-user-image"
                  />
                  <img
                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6332cad74bfa212dc72f1539_eliminate-shape-1.png"
                    loading="lazy"
                    alt="Eliminate Shape"
                    className="eliminate-shape-1"
                  />
                  <img
                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6332caf22a2058d93cbd6d57_eliminate-shape-2.png"
                    loading="lazy"
                    alt="Eliminate Shape"
                    className="eliminate-shape-2"
                  />
                </div>
              </div>
              <div id="w-node-_7567fb22-2cf4-6551-36b0-67cbebb1b7c7-44adaf1d" className="eliminate-gridbox-right">
                <div className="section-block">
                  <h2 className="section-title max-width-442">
                    Eliminate <span className="section-title-inner-style">IT Challenges</span> for your Business
                  </h2>
                  <div className="section-text">
                  Navigating IT complexities can be daunting, but SoftSavvy simplifies the process. Our team of professionals identifies and resolves your IT challenges, ensuring seamless operations and enhanced productivity.
                  </div>
                </div>
                <ul role="list" className="eliminate-list w-list-unstyled">
                  <li className="eliminate-list-item">
                    <div className="eliminate-list-style"></div>
                    <div className="eliminate-list-text">
                    We tailor our services to meet your unique needs, delivering reliable and scalable solutions that empower your business to thrive.
                    </div>
                  </li>
                  <li className="eliminate-list-item">
                    <div className="eliminate-list-style"></div>
                    <div className="eliminate-list-text">
                    Beyond problem-solving, we proactively work to prevent potential issues. Our approach includes regular system audits, continuous monitoring, and timely updates to keep your IT environment secure and efficient.
                    </div>
                  </li>
                  <li className="eliminate-list-item">
                    <div className="eliminate-list-style"></div>
                    <div className="eliminate-list-text">
                    By partnering with SoftSavvy, you can eliminate IT management stress and focus on growing your business. Let us handle your IT challenges so you can seize opportunities and drive innovation.
                    </div>
                  </li>
                  <li
                    data-w-id="4d5803c7-bc56-0c8b-5ae0-7d611a5efd41"
                    className="eliminate-list-item-dash-progress"
                    style={{ willChange: "width, height", height: "0%" }}
                  ></li>
                </ul>
                <a href="/quote" className="primary-button">Let's Start</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}