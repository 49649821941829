import React from 'react'
import NavBar from '../Component/NavBar'
import { Outlet } from "react-router-dom";
import Footer from '../Component/Footer';

export default function AppLayout() {
  return (
    <>
      <NavBar/>
      <Outlet />
      <Footer />
    </>
  )
}
