import React from 'react'
import CommonHelmet from '../../Component/shared/component/CommonHelmet'

export const QuoteHelmet = () => {
  return (
    <CommonHelmet
      title="Get a Quote - SoftSavvy"
      description="Request a quote for our software development, IT services, and other digital solutions. Let's work together to bring your vision to life."
      url="https://softsavvy.ng/quote"
      keywords="Softsavvy, Request a Quote, IT Services Quote, Software Development Quote, Digital Solutions Quote"
    />
  );
};
