import React from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import TextButton from "../../../../Component/shared/ui-lib/text-button";

import hero from "../../../../Component/images/hero.png"
import { useAppNavigation } from "../../../../hooks/use-app-navigation";
import { ReactTyped } from "react-typed";

export const HeroSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { navigateTo } = useAppNavigation();
 
    const heroText = [
        'IT <span style="color: #0077B6; text-decoration: underline;">Solutions</span> <br>for your <br>Business'
    ]

    const heroTextMob = [
        'IT <span style="color: #0077B6; text-decoration: underline;">Solutions</span> for <br>your Business'
    ]

    return (
        <section className="hero-section">
        <div className="hero-background">
          <div className="container w-container">
            <div className="hero-container-wrapper">
              <div className="hero-content">
                <h1 className="hero-title">IT <span className="hero-title-span">Solutions</span> for your Business</h1>
                <p className="hero-text">
                  We transform possibilities into realities! Experience cutting-edge development, comprehensive computer services, expert IT training, and robust IT management all under one roof.
                </p>
                <div className="hero-link-group">
                  <a href="/about" className="primary-button">Learn more</a>
                </div>
              </div>
              <div className="hero-image-block">
                <img src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63536b582cf25003d158f7c8_hero-image.png" loading="lazy" sizes="(max-width: 1919px) 100vw, 677px" srcSet="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63536b582cf25003d158f7c8_hero-image-p-500.png 500w, https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63536b582cf25003d158f7c8_hero-image.png 677w" alt="Hero Image" className="hero-image" />
                <img src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63536c2e4f3fdf00dafdcacb_hero-shape.png" loading="lazy" alt="Hero Shape Image" className="hero-shape" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}