import React from "react";

export const ServiceCard = ({ service }) => {

    return (
        <div role="listitem" className="service-collection-item w-dyn-item">
            <div className="service-single-item-box">
                <a href={`/services/${service.id}`} className="service-thumbnail-image-block w-inline-block">
                    <img
                        alt={service.title}
                        loading="lazy"
                        src={service.img}
                        sizes="100vw"
                        srcSet={`${service.img} 500w, ${service.img} 545w`}
                    />
                </a>
                <div className="service-content">
                    <a href={`/services/${service.id}`} className="service-title-link">{service.title}</a>
                    <div className="service-text">
                        {service.description}
                    </div>
                </div>
            </div>
        </div>
    )
}