import React from 'react';
import BulletPoint from './bullet-point';

const BulletPoints = ({ points }) => {
  return (
    <ul role="list">
      {points.map((point, index) => (
        <BulletPoint key={index} point={point}/>
      ))}
    </ul>
  );
};

export default BulletPoints;