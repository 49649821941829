import React from "react";
import { useServices } from "../../../../Entities/Services/model";
import { ServiceCard } from "../../../../Entities/Services/ui/service-card";

export const ServicesSection = () => {
    const { services } = useServices()

    return (
        <div id="Service-Section" className="service-section">
            <div className="section-gap-bottom-130">
                <div className="container w-container">
                    <div className="section-justify-center">
                        <div className="section-block max-width-475 text-center">
                            <h2 className="section-title">
                                Services <span className="section-title-inner-style">that we </span>Provide
                            </h2>
                            <div className="section-text">
                                We deliver tailored IT Services to meet yout unique needs and drive yoour success in the digital age.
                            </div>
                        </div>
                    </div>
                    <div className="service-collection-list-wrapper w-dyn-list">
                        <div role="list" className="service-collection-list w-dyn-items">
                            {
                                services.map((service) => <ServiceCard key={service.id} service={service} />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}