import React from 'react';
import { Box, Typography, Card, CardContent, Divider } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/system';


const StyledCard = styled(Card)({
    flex: 1,
    backgroundColor: '#E7FBFF',
    height: '600px',
    borderRadius: '30px',
    padding: '0px 50px',
    overflow: 'auto',
    boxShadow: 'none',
});

export const RecentPosts = () => {
    const posts = [
        {
            date: 'June 22, 2023',
            author: 'Asusoft',
            title: 'Everything you need to learn about IT Solution for your Company.'
        },
        {
            date: 'June 22, 2023',
            author: 'Asusoft',
            title: 'Everything you need to learn about IT Solution for your Company.'
        },
        {
            date: 'June 22, 2023',
            author: 'Asusoft',
            title: 'Everything you need to learn about IT Solution for your Company.'
        }
    ];

    return (
        <StyledCard>
            {posts.map((post, index) => (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    key={index}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                        borderBottom: index + 1 !== posts.length ? "1px solid" : undefined,
                        borderColor: index + 1 !== posts.length ? '#0077B6' : undefined,
                    }}
                >
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                            <CalendarTodayIcon sx={{ height: '20px', width: '20px', marginRight: '10px', color: '#0077B6' }} />
                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '12px', color: '#0077B6' }}>
                                {post.date}
                            </Typography>
                            <PersonIcon sx={{ height: '20px', width: '20px', marginRight: '10px', color: '#0077B6' }} />
                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '12px', color: '#0077B6' }}>
                                {post.author}
                            </Typography>
                        </Box>
                        <Typography variant="h5" sx={{
                            marginTop: '8px',
                            fontFamily: 'Rajdhani, sans-serif',
                            fontWeight: '900',
                            color: '#023E8A'
                        }}
                            textAlign={'left'}
                        >
                            {post.title}
                        </Typography>
                    </CardContent>
                </Box>
            ))}
        </StyledCard>
    );
}
