import {
    Typography,
    Box,
    useMediaQuery,
} from "@mui/material";
import { PageHeader } from "../../Component/shared/component/PageHeader";
import ContactForm from "./ui/contact-form";
import NewsLetter from "../../Component/shared/component/NewsLetter";
import { ContactHelmet } from "./helmet";
import Hero from "./hero";
import { useEffect, useRef } from "react";
import FormSection from "./form-section";

export default function ContactScreen() {
    const isMobile = useMediaQuery("(max-width:960px)");

    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -10% 0px'
            }
        );

        sectionsRef.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            sectionsRef.current.forEach((section) => {
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    return (
        <div>
            <ContactHelmet />
            <main className="main-wrapper">
                <div className="tsection" ref={(el) => sectionsRef.current[0] = el}>
                    <PageHeader
                        title={'Contact Us'}
                        description={`SoftSavvy is here to assist you with any questions or support your need. Reach out to us and let’s start a conversation.`}
                        location={[
                            { name: "Home", path: "/" },
                            { name: "Contact", path: "" }
                        ]}
                    />
                    <Hero />
                    <FormSection />
                </div>

                <div className="tsection" ref={(el) => sectionsRef.current[2] = el}>
                    <NewsLetter />
                </div>
            </main>
        </div>
    )
}