import React from 'react'
import CommonHelmet from '../../Component/shared/component/CommonHelmet';

export const AboutHelmet = () => {
  return (
    <CommonHelmet
      title="About SoftSavvy"
      description="Learn more about SoftSavvy, our mission, values, and the team dedicated to making your digital journey exceptional."
      url="https://softsavvy.ng/about"
      keywords="Softsavvy, Web Development, IT services, Digital Solutions, mobile app development, computer training, IT management, software development, graphic design, About SoftSavvy, SoftSavvy Team, Mission, Values"
    />
  );
};
