import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppNavigation } from "../../../hooks/use-app-navigation";

export const CompanyStats = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const {navigateTo} = useAppNavigation();

    return (
        <div className="funfact-section">
        <div className="section-gap-130">
          <div className="container w-container">
            <div className="funfact-block">
              <div className="funfact-single-item">
                <div className="funfact-number">95k</div>
                <div className="funfact-text">Happy Clients</div>
              </div>
              <div className="funfact-separator"></div>
              <div className="funfact-single-item">
                <div className="funfact-number">65+</div>
                <div className="funfact-text">Companies</div>
              </div>
              <div className="funfact-separator"></div>
              <div className="funfact-single-item">
                <div className="funfact-number">362</div>
                <div className="funfact-text">Projects Done</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}