import React, { useRef, useState } from 'react';
import { Snackbar, Alert } from "@mui/material";
import emailjs from '@emailjs/browser';

export default function FormSection() {
    const formRef = useRef();
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_CONTACT_TEMPLATE_ID,
            formRef.current,
            process.env.REACT_APP_USER_ID
        ).then((response) => {
            setSeverity("success");
            setMessage('Message sent successfully! We will get back to you soon.');
            setOpen(true);
            formRef.current.reset(); // Reset the form after successful submission
        }, (err) => {
            setSeverity("error");
            setMessage('Failed to send message. Please try again.');
            setOpen(true);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
  return (
    <div className="contact-form-section">
        <div className="section-gap-bottom-130">
          <div className="container w-container">
            <div className="contact-form-wrapper">
              <div className="contact-upper-text">Leave a <span style={{ color: '#0077B6' }}>message</span></div>
              <div className="contact-form-block w-form">
                <form ref={formRef} onSubmit={sendEmail} className='contact-form'>
                  <div className="contact-input-group">
                    <input className="default-input-field w-input" maxLength="256" name="from_name" data-name="from_name" placeholder="Name*" type="text" id="from_name" required />
                    <input className="default-input-field w-input" maxLength="256" name="from_email" data-name="from_email" placeholder="*Email" type="email" id="email" required />
                    <input className="default-input-field w-input" maxLength="256" name="from_phone" data-name="from_phone" placeholder="Phone*" type="tel" id="phone" required />
                  </div>
                  <div className="contact-input-group">
                    <textarea placeholder="Write here..." maxLength="5000" id="message" name="message" data-name="field" class="default-input-field contact-text-area w-node-_0433b0ec-3f89-81ce-9f2e-3b04ff192a61-84d9bf7c w-input" style={{ width: "768px", height: "234px;"}}></textarea>
                  </div>
                  <input type="submit" data-wait="Please wait..." className="primary-button margin-top-50 w-button" value="Send Now" />
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
