import React, { useState } from 'react';
import logo from "../images/logo.png";
import NavLinks from "./ui/NavLinks";
import { Link } from 'react-router-dom';

export default function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header-section">
            <div className="container w-container">
                <div className="header-top-area">
                    <div className="header-right-block">
                        <a href="mailto:info@softsavvy.ng" className="header-top-link">info@softsavvy.ng</a>
                        <div className="header-top-link-separator"></div>
                        <a href="https://wa.me/79950327429" className="header-top-link">+7 995 032 74 29</a>
                    </div>
                    {/* <div className="header-left-block">
                        <Link to="/login" className="header-top-link">Login</Link>
                        <div className="header-top-link-separator"></div>
                        <Link to="/signup" className="header-top-link">Register</Link>
                    </div> */}
                </div>
                <div className="header-horizontal-separator"></div>
                <div className="header-bottom-area">
                    <div
                        data-animation="over-left"
                        data-collapse="medium"
                        data-duration="400"
                        data-easing="ease"
                        data-easing2="ease"
                        role="banner"
                        className="header-navbar w-nav"
                    >
                        <div className="header-navbar-block">
                            <div className="logo-block">
                                <Link to="/" className="w-nav-brand" aria-label="home">
                                    <img
                                        src={logo}
                                        loading="lazy"
                                        alt="Logo Color"
                                        style={{
                                            height: '50px',
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className="header-menu-wrapper">
                                <nav role="navigation" className={`nav-menu w-nav-menu ${menuOpen ? 'open' : ''}`}>
                                    <div className="mobile-logo-block">
                                        <Link to="/" className="w-nav-brand" aria-label="home">
                                            <img
                                                src={logo}
                                                loading="lazy"
                                                alt="Logo Color"
                                                style={{
                                                    height: '50px'
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <NavLinks />
                                </nav>
                                <div className="header-event-button-group">
                                    <div
                                        className="menu-button w-nav-button"
                                        style={{ WebkitUserSelect: 'text' }}
                                        aria-label="menu"
                                        role="button"
                                        tabIndex="0"
                                        aria-controls="w-nav-overlay-0"
                                        aria-haspopup="menu"
                                        aria-expanded={menuOpen}
                                        onClick={toggleMenu}
                                    >
                                        <div className="menu-span"></div>
                                        <div className="menu-span"></div>
                                        <div className="menu-span menu-last-span"></div>
                                    </div>
                                    <Link to="/quote" className="primary-button small header-event-button">Get a quote</Link>
                                </div>
                            </div>
                        </div>
                        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
                    </div>
                </div>
            </div>
        </header>
    );
}
