import React from 'react'
import { Typography  } from "@mui/material";

export default function LinkItem({ label, onClick, }) {
    return (
        <Typography
            fontFamily="livvic, sans-serif"
            fontSize="16px"
            color="#EBFAFE"
            style={{
                flexGrow: 1
            }}
            onClick={onClick}
            sx={{
                "&:hover": {
                    cursor: "pointer"
                }
            }}
        >
            {label}
        </Typography>
    )
}