import React from 'react'
import CommonHelmet from '../../Component/shared/component/CommonHelmet'

export const ServicesHelmet = () => {
  return (
    <CommonHelmet
      title="Our Services - SoftSavvy"
      description="Discover the range of services offered by SoftSavvy, including software development, IT services, IT training, and IT management. We provide comprehensive digital solutions to meet your needs."
      url="https://softsavvy.ng/services"
      keywords="Softsavvy, IT Services, Software Development, IT Training, IT Management, Digital Solutions"
    />
  );
};
