import { useServices } from "../../../../Entities/Services/model";
import Team from "../../../../Component/images/team.jpg"
import ServicesList from "./services-list";

export default function Hero({ serviceID }) {
    const { services } = useServices();
    const service = services.find((service) => service.id === serviceID);

    return (
        <div className="service-details-top-block">
            <div className="service-details-top-left-block">
                <div className="service-details-top-show-image">
                    <img
                        src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/634f968cdf9f12456bb72015_service-details-top-show-image.jpg"
                        loading="lazy"
                        alt="Service Details How Image "
                    />
                </div>
            </div>
            <div className="service-details-top-right-block">
                <div className="service-top-manu-image-block">
                    <div className="service-top-image-block">
                        <img
                            src={Team}
                            loading="lazy"
                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 395px, (max-width: 1279px) 315px, 522px"
                            srcSet={`${Team} 500w, ${Team} 522w`}
                            alt={service.title}
                            className="image-cover"
                        />
                    </div>
                    <ServicesList title={service.title} />
                </div>
                <h2 className="section-title">{service.title}</h2>
                <div className="service-section-top-text w-richtext">
                    <p>{service.text} </p>
                </div>
            </div>
        </div>
    )
}