import React from "react";
import { Helmet } from "react-helmet";

const CommonHelmet = ({ title, description, url, keywords }) => {
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "IT Services",
        "provider": {
            "@type": "Organization",
            "name": "SoftSavvy",
            "url": "https://softsavvy.ng",
            "logo": "https://softsavvy.ng/path-to-logo.png"
        },
        "areaServed": {
            "@type": "Place",
            "name": "Global"
        },
        "description": description
    };

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content="https://softsavvy.ng/softsavvy-logo-92x192.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:site" content="@SoftSavvy_" />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://softsavvy.ng/softsavvy-logo-92x192.png" />
            <meta name="robots" content="index, follow" />
            <script type="application/ld+json">
                {JSON.stringify(jsonLd)}
            </script>
        </Helmet>
    );
};

export default CommonHelmet;
