import React from 'react'
import { Box, Typography, useMediaQuery, Link } from "@mui/material";
import Divider from '../shared/ui-lib/divider';
import SocialLinks from '../shared/ui-lib/social-links';
import { useNavigate } from 'react-router-dom';
import LinkItem from './ui/link-item';
import FlexBetween from '../shared/ui-lib/flex-between';

import logo from "../images/logo-white.png"
import { useAppNavigation } from '../../hooks/use-app-navigation';

export default function Footer() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const currentYear = new Date().getFullYear();
    const { navigateTo } = useAppNavigation()
    return (
        <Box >
            <Box
                padding={!isMobile ? "5rem 12rem" : "3rem"}
                style={{ backgroundColor: "#183D85", }}
            >
                <Box
                    display="flex"
                    gap={'2.5rem'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    justifyContent="space-between">
                    <Box
                        display={'flex'}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        flexDirection={'column'}
                        width={isMobile ? '100%' : '30%'}
                    >
                        <FlexBetween gap="1.5rem" marginLeft={'-0.5rem'} marginBottom={'1rem'}
                            onClick={() => navigateTo('')}
                            sx={{
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            }}>
                            <img src={logo} alt="Logo" style={{ height: '3.5rem' }} />
                        </FlexBetween>
                        <Typography
                            fontFamily="livvic, sans-serif"
                            fontSize="16px"
                            color="#EBFAFE"
                            textAlign={'left'}
                        >
                            Transforming possibilities into realities! Experience cutting-edge development, comprehensive computer services, expert IT training, and robust IT management all under one roof.
                        </Typography>
                        <Box display="flex" gap="1.5rem" marginTop={'2.5rem'}>
                            <SocialLinks />
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        flexDirection={'column'}
                        width={isMobile ? '100%' : '15%'}
                    >
                        <Typography
                            fontSize="22px"
                            gutterBottom
                            fontWeight='bold'
                            fontFamily="Rajdhani"
                            color={"#EBFAFE"}
                        >
                            Links
                        </Typography>
                        <Box display="flex" flexDirection='column' marginTop="1.5rem" gap="0.5rem" textAlign={'left'}>
                            <LinkItem label={'Home'} onClick={() => { navigateTo('/') }} />
                            <LinkItem label={'Our Services'} onClick={() => navigateTo('services')} />
                            {/* <LinkItem label={'Projects'} onClick={() => navigateTo('projects')} /> */}
                            <LinkItem label={'Blog Posts'} onClick={() => navigateTo('/')} />
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        flexDirection={'column'}
                        width={isMobile ? '100%' : '15%'}
                    >
                        <Typography
                            fontSize="22px"
                            gutterBottom
                            fontWeight='bold'
                            fontFamily="Rajdhani"
                            color="#EBFAFE"
                        >
                            Company
                        </Typography>
                        <Box display="flex" flexDirection='column' marginTop="1.5rem" gap="0.5rem" textAlign={'left'}>
                            <LinkItem label={'About Us'} onClick={() => navigateTo('about')} />
                            {/* <LinkItem label={'Our Team'} onClick={() => navigateTo('about')} /> */}
                            <LinkItem label={'Get a Quote'} onClick={() => navigateTo('quote')} />
                            <LinkItem label={'Contact Us'} onClick={() => navigateTo('contact')} />
                            <LinkItem label={'Carrers'} onClick={() => navigateTo('/')} />
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        flexDirection={'column'}
                        width={isMobile ? '100%' : '15%'}
                    >
                        <Typography
                            fontSize="22px"
                            gutterBottom
                            fontWeight='bold'
                            fontFamily="Rajdhani"
                            color="#EBFAFE"
                        >
                            Contact
                        </Typography>
                        <Box display="flex" flexDirection='column' marginTop="1.5rem" gap="0.7rem" textAlign={'left'}>
                            <LinkItem label={'298A, Second Floor, Main Transformer Street, Damagum, Yobe, Nigeria.'} onClick={() => { }} />
                            <LinkItem label={'+234 906 811 68 32 (call only)'} onClick={() => { window.location.href = 'tel:+2349068116832'; }} />
                            <LinkItem
                                label={'+7 995 032 74 29'}
                                onClick={() => {
                                    window.open('https://wa.me/79950327429', '_blank');
                                }}
                            />
                            <LinkItem label={'info@softsavvy.ng'} onClick={() => { window.location.href = 'mailto:info@softsavvy.ng'; }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    height: '5rem',
                    backgroundColor: '#043676',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '1rem',
                }}
            >
                <Typography variant="body1">
                    Copyright © SoftSavvy {currentYear}
                </Typography>
            </Box>
        </Box>
    )
}