import React from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Item from "./ui/why-us-item";

import man from "../../../../Component/images/standing-man.png"
import experts from "../../../../Component/icons/expert-team.png"
import quick from "../../../../Component/icons/quick-action.png"
import latest from "../../../../Component/icons/latest-tech.png"

export const WhyUsSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");

    return (
        // <Box
        //     padding={!isMobile ? "0rem 12rem" : "0rem 1rem"}
        //     marginTop={!isMobile ? '5.5rem' : undefined}
        // >
        //     <Box
        //         display="flex"
        //         flexDirection={isMobile ? "column" : "row"}
        //         justifyContent="space-between"
        //         alignItems={'center'}
        //     >

        //         <Box
        //             display={'flex'}
        //             flexDirection={'column'}
        //             textAlign={'left'}
        //             maxWidth={isMobile ? "100%" : "50%"}
        //             mb={isMobile ? "2rem" : "1rem"}
        //             marginTop={isMobile ? "2rem" : "0"}
        //             marginBottom={!isMobile ? "8rem" : "0"}
        //         >
        //             <Typography
        //                 variant={isMobile ? "h4" : "h2"}
        //                 fontWeight="bold"
        //                 gutterBottom
        //                 fontSize={isMobile ? '2rem' : '3.2rem'}
        //                 fontFamily="Rajdhani, sans-serif"
        //                 color={"#023E8A"}
        //                 textAlign={'left'}
        //             >
        //                 Why should you <span style={{ color: '#0077B6' }}>Choose SoftSavvy?</span> Because...
        //             </Typography>
        //             <Typography
        //                 fontFamily="Livvic, sans-serif"
        //                 sx={{ color: "#000000" }}
        //                 textAlign={'left'}
        //             >
        //                At <span style={{ color: '#023E8A' }}>SoftSavvy, </span> we blend expertise, efficiency, and innovation to deliver unparalleled IT solutions that drive business success. Here's why we're your best choice for navigating the digital landscape:
        //             </Typography>
        //             <Box display={'flex'} flexDirection={'column'} gap={'3rem'} marginTop={'2rem'}>
        //                 <Item label={'Expert Team'} icon={experts} text={'Our seasoned professionals bring a wealth of experience and deep technical knowledge, ensuring top-notch service and solutions.'} />
        //                 <Item label={'Quick Action'} icon={quick} text={'We prioritize swift responses and rapid problem resolution to keep your business running smoothly without interruptions.'} />
        //                 <Item label={'Latest Technology'} icon={latest} text={'We leverage cutting-edge technology to provide advanced, scalable, and secure solutions tailored to your evolving needs.'} />
        //             </Box>
        //         </Box>
        //         <Box
        //             maxWidth={isMobile ? "100%" : "45%"}
        //             display="flex"
        //             marginLeft={!isMobile ? '2rem' : undefined}

        //         >
        //             <img
        //                 src={man}
        //                 alt="Hero"
        //                 style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
        //             />
        //         </Box>
        //     </Box>

        // </Box>
        <div className="choose-section">
            <div className="section-gap-bottom-130">
                <div className="container w-container">
                    <div className="choose-grid-layout">
                        <div className="choose-gridbox-left">
                            <div className="section-block">
                                <h2 className="section-title">Why should you <span className="section-title-inner-style">Choose SoftSavvy?</span> Because...</h2>
                                <div className="section-text">At <span style={{ color: '#023E8A' }}>SoftSavvy, </span> we blend expertise, efficiency, and innovation to deliver unparalleled IT solutions that drive business success. Here's why we're your best choice for navigating the digital landscape:</div>
                            </div>
                            <ul role="list" className="choose-list w-list-unstyled">
                                <Item
                                    label={'Expert Team'}
                                    text={"Our seasoned professionals bring a wealth of experience and deep technical knowledge, ensuring top-notch service and solutions."}
                                    icon={"https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6333d0043cda720ecf78eaa9_choose-icon-2.png"}
                                />
                                <Item
                                    label={'Quick Action'}
                                    text={"We prioritize swift responses and rapid problem resolution to keep your business running smoothly without interruptions."}
                                    icon={"https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6333d0043cda720ecf78eaa9_choose-icon-2.png"}
                                />
                                <Item
                                    label={'Latest Technology'}
                                    text={"We leverage cutting-edge technology to provide advanced, scalable, and secure solutions tailored to your evolving needs."}
                                    icon={"https://assets.website-files.com/6330105a320e0e0ed5adaf1c/6333d0040c2c55c05a0ba3f2_choose-icon-3.png"}
                                />
                            </ul>
                        </div>
                        <div className="choose-gridbox-right">
                            <div className="choose-image-box">
                                <img src={man} loading="lazy" alt="Choose Image" className="choose-image" />
                                <div className="choose-inner-text-box-1">
                                    <div className="text-block">100% Clients Satisfaction</div>
                                </div>
                                <div className="choose-inner-text-box-2">
                                    <div className="text-block">Get Perfect Solution for your Problems</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}