import './App.css';
import MaintenancePage from './MaintenancePage';
import AppLayout from './Layout';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomeScreen from './Screens/HomeScreen';
import ServicesScreen from './Screens/Services';
import ServiceInfoScreen from './Screens/Services/service-info';
import AboutScreen from './Screens/About';
import ProjectScreen from './Screens/Project';
import BlogScreen from './Screens/Blog';
import QuoteScreen from './Screens/Quote';
import ContactScreen from './Screens/Contact';
import ProjectInfoScreen from './Screens/Project/project-info';
import NotFoundScreen from './Component/NotFound';
import ArticleReader from './Screens/Blog/reader';

function App() {

  return (
    <div className="page-wrapper">
      {/* <MaintenancePage /> */}
     <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<HomeScreen />} />
            <Route path="services" element={<ServicesScreen />} />
            <Route path="services/:serviceID" element={<ServiceInfoScreen />} />
            <Route path="about" element={<AboutScreen />} />
            {/* <Route path="blog" element={<BlogScreen />} />
            <Route path="blog/:articleID" element={<ArticleReader />} /> */}
            <Route path="contact" element={<ContactScreen />} />
            <Route path="quote" element={<QuoteScreen />} />
            <Route path="*" element={<NotFoundScreen />} />
          </Route>
        </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default App;