import { useServices } from "../../../../Entities/Services/model";
import BulletPoints from "../../../../Component/shared/ui-lib/bullet-points";

export default function Benefits({ serviceID }) {
    const { services } = useServices();

    const service = services.find((service) => service.id === serviceID);

    return (
        <div className="service-benifit-block">
        <div className="service-benifit-content">
            <h2 className="section-title">Benefits</h2>
            <div className="w-richtext">
                {service.benefit.text}
                <BulletPoints
                    points={service.benefit.benefits}
                />
            </div>
        </div>
        <div className="service-benifit-image-block">
            <img
                alt=""
                loading="lazy"
                src={service.benefit.img}
                className="image-cover"
            />
        </div>
    </div>
    )
}