import { useServices } from "../../../../Entities/Services/model";

export default function How({ serviceID }) {
    const { services } = useServices();
    const service = services.find((service) => service.id === serviceID);

    return (
        <div className="service-how-to-block">
        <div id="w-node-_88bd72f0-62c1-75a1-0a26-97bd916edc8b-7236d981" className="service-how-to-image-block">
            <img
                alt={service.title}
                loading="lazy"
                src={service.how.img}
                className="image-cover"
            />
        </div>
        <div className="service-how-to-content">
            <h2 className="section-title">How it works</h2>
            <div className="inner-quote-block">
                <div className="service-quote w-richtext">
                   {service.how.text}
                </div>
                <div className="inner-quote-border-left"></div>
            </div>
        </div>
    </div>
    )
}