import { useServices } from "../../../../Entities/Services/model";
import BulletPoints from "../../../../Component/shared/ui-lib/bullet-points";

export default function Areas({ serviceID }) {
    const { services } = useServices();

    const service = services.find((service) => service.id === serviceID);

    return (
        <div className="service-area-block">
            <div className="service-area-content">
                <h2 className="section-title">Which area we cover</h2>
                <div className="service-subtitle-text">
                  {service.area.shortDescription}
                </div>
                <div className="w-richtext">
                    <p> {service.area.description}</p>
                    <BulletPoints points={service.area.areas} />
                </div>
            </div>
            <div className="service-area-image-block">
                <img
                    alt={service.area.img}
                    loading="lazy"
                    src={service.area.img}
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 345px, (max-width: 1279px) 447.5px, 552.5px"
                    srcSet={`${service.area.img} 500w, ${service.area.img} 545w`}
                    className="image-cover"
                />
            </div>
        </div>
    )
}