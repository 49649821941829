// import React, { useEffect, useState } from 'react'
// import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc, getDoc } from "firebase/firestore";
// import { db } from '../../firebase.config';


import ITCONST from "../../Component/images/It-cons.jpeg"
import WEBDEV from "../../Component/images/web-dev.jpeg"
import SOFTDEV from "../../Component/images/soft-dev.jpeg"
import ITBENEFIT from "../../Component/images/it-training-benefit.jpg"

import ITCONSTL from "../../Component/images/it-const.jpg"
import ITCONSTLHOW from "../../Component/images/it-const-how.jpg"
import ITCONSTLMINI from "../../Component/images/it-const-mini.jpg"

export const useServices = () => {
    // const [services, setServices] = useState([])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const q = query(collection(db, "services"));
    //         onSnapshot(q, (querySnapshot) => {
    //             const services = [];
    //             querySnapshot.forEach((doc) => {
    //                 services.push({ id: doc.id, ...doc.data() });
    //                 console.log({ id: doc.id, ...doc.data() })
    //             });
    //             setServices(services)
    //         });
    //     }
    //     fetchData()
    // }, [])


    const getServiceById = async (id) => {
        // const docRef = doc(db, "services", id);
        // const docSnap = await getDoc(docRef);

        // if (docSnap.exists()) {
        //     console.log("Document data:", docSnap.data());
        //     return({id: id, ...docSnap.data()})
        // } else {
        // // docSnap.data() will be undefined in this case
        // console.log("No such document!");
        // }
    }

    
    const services = [
        {
            id: "software-development",
            img: SOFTDEV,
            description: "Crafting custom, robust, and scalable software solutions for your unique business needs.",
            title: "Software Development",
            text: "At SoftSavvy, we offer top-quality software development services tailored to your business needs. Our experienced developers create robust, scalable, and secure software solutions that drive your business forward. From sophisticated enterprise systems to intuitive mobile apps, we transform complex requirements into user-friendly applications, ensuring enhanced operational efficiency and performance.",
            area: {
                "img": WEBDEV,
                "shortDescription": "In software development, we cover a wide range of services to meet your specific business needs.",
                "description": "We at SoftSavvy specialize in various areas of software development, ensuring we meet your unique business needs.",
                "areas": [
                    "Custom Application Development: Tailored software solutions for your specific business processes.",
                    "Enterprise Software Solutions: Scalable and robust applications to streamline enterprise operations.",
                    "Mobile App Development: Intuitive and engaging mobile applications for both iOS and Android platforms."
                ]
            },
            how: {
                img: ITCONST,
                text: `Our software development process at SoftSavvy ensures your vision is realized efficiently and effectively. We begin by understanding your requirements through in-depth consultations to define the project scope and align our solutions with your business goals.

Next, we design a detailed blueprint with wireframes and prototypes to visualize the final product. Our team collaborates closely with you, incorporating your feedback to refine the design.

During development, we create scalable and robust solutions using the latest technologies and best practices. We maintain constant communication and provide regular updates to keep the project on track.

We conduct thorough testing and quality assurance to guarantee the software’s reliability, security, and performance. After successful testing, we deploy the software seamlessly.

Finally, we offer continuous support and updates to keep your software running smoothly. Our maintenance services include regular updates, troubleshooting, and enhancements to ensure your software adapts to evolving business needs.`
            },
            benefit: {
                img: SOFTDEV,
                text: `Choosing SoftSavvy for your software development needs comes with numerous benefits. Our expertise and dedication ensure that you receive a high-quality, custom solution that enhances your business operations. We focus on delivering software that is not only robust and secure but also tailored to meet the unique challenges and goals of your organization. By partnering with us, you gain access to cutting-edge technology and a team committed to your success, ensuring your software continues to perform efficiently as your business evolves.`,
                benefits: [
                    "Enhanced Efficiency: Our solutions are designed to streamline your operations.",
                    "Scalability: Easily expand your software as your business grows.",
                    "Expert Support: Continuous support to keep your software updated and secure."
                ]
            }
        },
        {
            id: "web-development",
            img: WEBDEV,
            description: "Creating responsive, engaging websites to elevate your online presence.",
            title: "Web Development",
            text: "Elevate your digital presence with SoftSavvy’s bespoke web development services. Our talented team designs and develops responsive, visually appealing websites that engage your audience and drive business growth. We focus on delivering a seamless user experience, ensuring that your website is both functional and aesthetically pleasing, tailored to meet your specific needs and objectives.",
            area: {
                "img": ITCONST,
                "shortDescription": "In web development, we cover all aspects of creating a compelling online presence.",
                "description": "Our web development services cover all aspects of creating a compelling online presence.",
                "areas": [
                    "Responsive Web Design: Creating websites that provide an optimal user experience across all devices.",
                    "E-commerce Solutions: Developing robust online stores with secure payment gateways.",
                    "Content Management Systems: Implementing user-friendly CMS platforms for easy website management."
                ]
            },
            how: {
                img: WEBDEV,
                text: `The web development process at SoftSavvy is crafted to enhance your digital presence effectively. We begin by understanding your business requirements and goals, ensuring our solutions align with your vision.

We design a detailed blueprint, including wireframes and prototypes, to visualize the final website. Our team collaborates closely with you to refine the design, ensuring it meets your expectations and resonates with your target audience.

During the development phase, our skilled developers build a responsive, user-friendly website using the latest technologies and best practices. We focus on creating a site that is not only visually appealing but also highly functional and secure.

Thorough testing and quality assurance are conducted to ensure the website performs optimally across all devices and platforms. Once testing is complete, we deploy the website, ensuring a smooth and seamless launch.

Post-launch, we offer continuous support and updates to keep your website running smoothly. Our maintenance services include regular updates, troubleshooting, and enhancements, ensuring your site remains up-to-date and effective.`
            },
            benefit: {
                img: WEBDEV,
                text: `Partnering with SoftSavvy for web development brings several benefits, ensuring your online presence is strong and effective. Our expertise in creating responsive and visually appealing websites helps attract and engage your target audience. We prioritize user experience, security, and SEO optimization to maximize your site’s performance and visibility. With our continuous support and maintenance services, your website remains up-to-date and operates smoothly, providing a reliable platform for your business to grow and succeed online.`,
                benefits: [
                    "Increased Engagement: Websites designed to captivate and engage visitors.",
                    "SEO Optimization: Improved search engine visibility to attract more traffic.",
                    "Ongoing Support: Continuous maintenance and updates to keep your site current."
                ]
            }
        },
        {
            id: "computer-services",
            img: ITCONST,
            description: "Reliable IT support, maintenance, and troubleshooting for seamless operations.",
            title: "Computer Services",
            text: "Ensure the seamless operation of your IT infrastructure with SoftSavvy’s comprehensive computer services. Our team provides reliable technical support, hardware and software maintenance, and troubleshooting to keep your systems running smoothly. We offer proactive solutions to minimize downtime and enhance productivity, allowing you to focus on your core business activities.",
        area: {
            "img": WEBDEV,
            "shortDescription": "In computer services, we cover a wide range of IT support and maintenance needs.",
            "description": "SoftSavvy provides comprehensive computer services to keep your IT systems running smoothly.",
            "areas": [
                "Technical Support: Offering prompt assistance to resolve IT issues quickly.",
                "Hardware Maintenance: Regular maintenance and upgrades to ensure optimal performance.",
                "Software Troubleshooting: Diagnosing and fixing software problems to prevent downtime."
            ]
        },
        how: {
            img: ITBENEFIT,
            text: `Computer services at SoftSavvy are designed to ensure your IT systems run smoothly and efficiently. We start with an in-depth analysis of your current IT setup, identifying any issues and areas for improvement.

We offer technical support, performing hardware and software maintenance to keep your systems in optimal condition. Our team provides troubleshooting services to quickly resolve any problems that arise, minimizing downtime and disruption to your operations.

Throughout the process, we maintain open communication with you, providing regular updates and ensuring your IT needs are met. We also offer proactive monitoring and preventive measures to keep your systems secure and efficient.

Finally, we provide continuous support and maintenance to ensure your IT infrastructure remains reliable and effective. Our ongoing services include regular updates, security enhancements, and performance optimizations, helping you stay ahead in the ever-evolving tech landscape.`
        },
        benefit: {
            img: SOFTDEV,
            text: `Choosing SoftSavvy for computer services ensures that your IT infrastructure is in expert hands, enhancing overall business performance. Our comprehensive support and maintenance services keep your systems reliable and secure, minimizing downtime and increasing productivity. We implement proactive measures to prevent issues and ensure your IT environment is always optimized. With our dedicated team, you can focus on your core business activities, knowing that your IT needs are managed effectively and efficiently.`,
            benefits: [
                "Reliable Systems: Ensuring your IT systems are always operational.",
                "Enhanced Security: Implementing measures to protect your data and systems.",
                "Increased Productivity: Minimizing downtime and improving efficiency."
            ]
        }
        },
//         {
//             id: "IT-training",
//             img: SOFTDEV,
//             description: "Enhance your skills with our comprehensive IT training programs.",
//             title: "IT Training",
//             text: "Stay competitive in the tech industry with SoftSavvy’s comprehensive IT training programs. Our courses cater to all skill levels, from beginners to advanced professionals, providing hands-on workshops, online modules, and personalized coaching. Equip yourself with the latest skills and knowledge to excel in the digital age with our expert-led training programs.",
//             area: {
//                 "img": WEBDEV,
//                 "shortDescription": "In IT training, we cover a range of programs to enhance your technical skills and knowledge.",
//                 "description": "We offer a range of IT training programs to enhance your technical skills and knowledge.",
//                 "areas": [
//                     "Beginner Courses: Introduction to fundamental IT concepts and basic technical skills.",
//                     "Advanced Workshops: In-depth training on advanced technologies and industry best practices.",
//                     "Customized Training Programs: Tailored training sessions to meet the specific needs of your team."
//                 ]
//             },
//             how: {
//                 img: ITCONSTLHOW,
//                 text: `IT training programs at SoftSavvy are designed to equip you with the latest skills and knowledge. We begin by assessing your current skill levels and identifying areas for improvement.

// We offer a variety of courses tailored to different skill levels, from beginners to advanced professionals. Our training includes hands-on workshops, online modules, and personalized coaching, ensuring a comprehensive learning experience.

// Throughout the training, we provide practical exercises and real-world scenarios to help you apply the knowledge gained. Our instructors are industry experts who provide valuable insights and guidance.

// After the training, we offer continuous support and resources to help you stay updated with the latest industry trends and technologies. This ensures you are well-prepared to tackle the challenges of the ever-evolving tech landscape.`
//             },
//             benefit: {
//                 img: ITBENEFIT,
//                 text: `Choosing SoftSavvy for IT training ensures you and your team are equipped with the latest skills and knowledge to excel in the digital world. Our comprehensive training programs are designed to enhance your technical proficiency and career prospects. By investing in our training, you gain practical, hands-on experience with the latest technologies and industry best practices. Our continuous support and resource updates help you stay ahead of industry trends, making you more competitive and effective in your professional role.`,
//                 benefits: [
//                     "Skill Enhancement: Gain up-to-date knowledge and skills.",
//                     "Career Advancement: Improved skills lead to better job prospects.",
//                     "Flexible Learning: Training programs designed to fit your schedule."
//                 ]
//             }
//         },
        {
            id: "logo-design",
            img: ITCONST,
            description: "Designing unique and impactful logos to represent your brand.",
            title: "Logo Design",
            text: "Create a lasting impression with SoftSavvy’s professional logo design services. Our creative team crafts unique and memorable logos that encapsulate your brand’s identity and values. Through a collaborative process, we ensure that your logo reflects your brand’s personality and vision, helping you establish a strong and recognizable brand identity.",
            area: {
                "img": WEBDEV,
                "shortDescription": "In logo design, we cover all aspects of creating a strong and recognizable brand identity.",
                "description": "Our logo design services ensure your brand is visually appealing and easily recognizable.",
                "areas": [
                    "Brand Identity Development: Crafting logos that reflect your brand’s values and vision.",
                    "Multiple Concept Designs: Providing various design options to choose the perfect logo.",
                    "Logo Optimization: Ensuring your logo looks great across all platforms and media."
                ]
            },
            how: {
                img: ITCONST,
                text: `Our logo design process at SoftSavvy starts with understanding your brand, values, and vision. We conduct in-depth consultations to gather all necessary information and define the project scope.

We brainstorm and create multiple design concepts, presenting you with a variety of options to choose from. Our team collaborates closely with you, incorporating your feedback to refine the designs and ensure they align with your brand identity.

Once a final design is selected, we polish and optimize the logo for various uses, ensuring it looks great across all platforms and media. We provide you with all necessary files and guidelines for using your new logo effectively.

Finally, we offer continuous support to ensure your logo remains effective as your brand evolves. This includes providing updates and adjustments as needed to keep your brand identity fresh and relevant.`
            },
            benefit: {
                img: ITBENEFIT,
                text: `Partnering with SoftSavvy for logo design ensures your brand makes a strong, lasting impression. Our professional designs enhance your brand’s recognition and appeal, helping you stand out in a competitive market. We create logos that are versatile and optimized for various platforms and media, ensuring consistent and effective branding. With our collaborative approach, you receive a logo that truly represents your brand’s identity and values, setting the foundation for a powerful visual presence.`,
                benefits: [
                    "Brand Recognition: Unique logos that stand out and are memorable.",
                    "Professional Appeal: High-quality designs that reflect your brand’s professionalism.",
                    "Versatile Use: Logos optimized for various platforms and media."
                ]
            }
        },
        {
            id: "it-consultation",
            img: ITBENEFIT,
            mini: ITCONSTLMINI,
            description: "Optimize your technology with our expert, tailored IT consultation services.",
            title: "IT Consultation",
            text: "Leverage the expertise of SoftSavvy’s IT consultants to optimize your technology investments and enhance your business efficiency. We work closely with you to understand your unique challenges and provide strategic, tailored solutions that align with your business goals. Our comprehensive IT consultation services empower you to navigate the tech landscape confidently and effectively.",
            area: {
                "img": WEBDEV,
                "shortDescription": "In IT Consultation, we cover a wide range of IT issues your company might face and provide effective solutions.",
                "description": "SoftSavvy offers comprehensive IT consultation services to address a wide range of business challenges.",
                "areas": [
                    "IT Infrastructure Assessment: Evaluating your current IT setup to identify strengths and areas for improvement.",
                    "Strategic IT Planning: Developing long-term IT strategies aligned with your business goals.",
                    "Cybersecurity Solutions: Implementing advanced security measures to protect your data and systems."
                ]
            },
            how: {
                img: ITCONSTLHOW,
                text: `During IT consultation at SoftSavvy, we begin with a comprehensive assessment of your current IT infrastructure and needs. We engage in detailed discussions to understand your business challenges and objectives, allowing us to develop a tailored strategy that aligns with your goals.

We then create a strategic plan that outlines actionable steps to optimize your IT environment. Our consultants provide expert recommendations and insights, helping you make informed decisions about technology investments and improvements.

Throughout the implementation phase, we offer hands-on support to ensure seamless integration and adoption of the proposed solutions. Our team works closely with yours to address any issues and ensure a smooth transition.

Finally, we offer continuous monitoring and optimization services. This ongoing support helps maintain the efficiency and security of your IT systems, adapting to new challenges and ensuring your technology infrastructure remains robust and effective.`
            },
            benefit: {
                img: SOFTDEV,
                text: `Engaging SoftSavvy for IT consultation offers numerous benefits, ensuring that your business leverages the latest technology for optimal performance. Our strategic insights and tailored solutions help you make informed decisions that enhance efficiency and productivity. We provide comprehensive support that includes ongoing monitoring and optimization, ensuring your IT infrastructure remains robust and secure. With our expert guidance, you can navigate the complexities of the tech landscape confidently, knowing you have a partner dedicated to your success.`,
                benefits: [
                    "Strategic Insights: Expert advice to align your IT strategy with business goals.",
                    "Cost Efficiency: Solutions designed to optimize your IT spend and improve ROI.",
                    "Enhanced Security: Implementing best practices to secure your IT environment."
                ]
            }
        }
];



    return { services, getServiceById }
}