import {
    Typography,
    Box,
    useMediaQuery
} from "@mui/material";
import mission from "../../../Component/images/mission.png"

export default function Mission() {
    const isMobile = useMediaQuery("(max-width:960px)");

    return (
        // <Box
        //     display="flex"
        //     flexDirection={isMobile ? "column" : "row"}
        //     justifyContent="space-between"
        //     alignItems={'center'}
        //     marginTop={'1rem'}
        //     marginBottom={'1rem'}
        //     padding={!isMobile ? "5rem 10rem" : "3rem 1rem"}
        // >
        //     <Box
        //         display={'flex'}
        //         flexDirection={'column'}
        //         textAlign={'left'}
        //         maxWidth={isMobile ? "100%" : "45%"}
        //         mb={isMobile ? "2rem" : "0"}
        //         marginTop={isMobile ? "2rem" : "0"}
        //         marginLeft={!isMobile ? '2rem' : undefined}
        //     >
        //         <Box display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
        //             <Typography
        //                 fontFamily="Livvic, sans-serif"
        //                 sx={{ color: '#0077B6' }}
        //                 textAlign={'left'}
        //                 fontWeight={'bold'}
        //                 fontSize={'18px'}
        //             >
        //                 Mission / <span style={{ color: '#023E8A' }}>Vision </span>
        //             </Typography>

        //             <Typography
        //                 fontFamily="Livvic, sans-serif"
        //                 sx={{ color: "#000000" }}
        //                 textAlign={'left'}
        //             >
        //                 We set our <span style={{ color: '#023E8A' }}>Mission </span> to empower businesses and individuals through technology. At <span style={{ color: '#023E8A' }}>SoftSavvy, </span> we are committed to excellence, integrity, and customer satisfaction.
        //                 Our vision is to be at the forefront of emerging technologies and trends, constantly pushing the boundaries of what’s possible. We strive to build lasting relationships with our clients based on trust and mutual success. Join us on this journey to transform possibilities into realities.
        //             </Typography>
        //             <Typography
        //                 fontFamily="Livvic, sans-serif"
        //                 sx={{ color: "#000000" }}
        //                 textAlign={'left'}
        //             >
        //                 Our <span style={{ color: '#023E8A' }}>Vision </span> is to be at the forefront of emerging technologies and trends, constantly pushing the boundaries of what’s possible. We strive to build lasting relationships with our clients based on trust and mutual success. Join us on this journey to transform possibilities into realities.
        //             </Typography>
        //         </Box>
        //     </Box>
        //     {!isMobile && <Box
        //         maxWidth={isMobile ? "100%" : "45%"}
        //         display="flex"
        //         alignSelf={'flex-end'}
        //     >
        //         <img
        //             src={mission}
        //             alt="Mission"
        //             style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
        //         />
        //     </Box>}
        // </Box>
        <div className="about-section">
        <div className="section-gap-top-130 section-gap-bottom-130">
            <div className="container w-container">
                <div className="about-block">
                    <div className="about-content">
                        <h2 className="section-title">Mission / <span className="section-title-inner-style"> Vision</span></h2>
                        <p className="about-subtitle paragraph-medium"> We are dedicated to advancing technological literacy and empowerment. Our mission is to provide individuals and businesses with the tools, training, and exper'se needed to excel in the digital age.</p>
                        <p>  We strive to be at the forefront of emerging technologies and trends, constantly pushing the boundaries of what’s possible. With a commitment to excellence, expertese, and customer satisfaction, our goal is to become the go-to partner for individuals and businesses, empowering them to achieve their full potential through the power of technology. </p>
                    </div>
                    <div className="about-image-block">
                        <img src={mission} loading="lazy" alt="About Image" style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }} />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}