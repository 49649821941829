import React from 'react'
import CommonHelmet from '../../Component/shared/component/CommonHelmet'

export const ContactHelmet = () => {
  return (
    <CommonHelmet
      title="Contact Us - SoftSavvy"
      description="Get in touch with SoftSavvy for inquiries, support, or feedback. We're here to help you with all your IT needs."
      url="https://softsavvy.ng/contact"
      keywords="Softsavvy, Contact SoftSavvy, IT Support, Customer Service, IT Inquiries"
    />
  );
};
