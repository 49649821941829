import React from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";

import man from "../../../../Component/images/standing-man.png"

export const Hero = () => {
    const isMobile = useMediaQuery("(max-width:960px)");

    return (
        // <Box
        //     padding={!isMobile ? "0rem 8rem" : "0rem 1rem"}
        //     marginTop={!isMobile ? '5.5rem' : undefined}
        // >
        //     <Box
        //         display="flex"
        //         flexDirection={isMobile ? "column" : "row"}
        //         justifyContent="space-between"
        //         alignItems={'center'}
        //     >

        //         <Box
        //             display={'flex'}
        //             flexDirection={'column'}
        //             textAlign={'left'}
        //             maxWidth={isMobile ? "100%" : "40%"}
        //             mb={isMobile ? "2rem" : "1rem"}
        //             marginTop={isMobile ? "2rem" : "0"}
        //             marginBottom={!isMobile ? "8rem" : "0"}
        //         >
        //             <Typography
        //                 variant={isMobile ? "h3" : "h2"}
        //                 fontWeight="bold"
        //                 gutterBottom
        //                 fontSize={isMobile ? '2rem' : '3.2rem'}
        //                 fontFamily="Rajdhani, sans-serif"
        //                 color={"#023E8A"}
        //                 textAlign={'left'}
        //             >
        //                 We have the <span style={{ color: '#0077B6' }}>best IT Services </span> to solve your <span style={{ color: '#0077B6' }}>Problems</span>
        //             </Typography>
        //             <Box display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
        //                 <Typography
        //                     fontFamily="Livvic, sans-serif"
        //                     sx={{ color: "#023E8A" }}
        //                     textAlign={'left'}
        //                     fontSize={'18px'}
        //                 >
        //                     At SoftSavvy, we offer top-tier IT services designed to address your unique challenges and propel your business forward.
        //                 </Typography>
        //                 <Typography
        //                     fontFamily="Livvic, sans-serif"
        //                     sx={{ color: "#000000" }}
        //                     textAlign={'left'}
        //                 >
        //                     At <span style={{ color: '#023E8A' }}>SoftSavvy, </span> We specialize in custom software development, creating robust and scalable applications tailored to your specific needs. Whether you need a sophisticated enterprise system or a user-friendly mobile app, we bring your vision to life with precision and excellence.
        //                 </Typography>

        //                 <Typography
        //                     fontFamily="Livvic, sans-serif"
        //                     sx={{ color: "#000000" }}
        //                     textAlign={'left'}
        //                 >
        //                    Our <span style={{ color: '#023E8A' }}>IT Training </span> programs are designed to keep your skills up-to-date in the fast-evolving tech industry. 
        //                    We offer courses for all skill levels, equipping individuals and teams with the latest technological knowledge.
        //                 </Typography>
        //                 <Typography
        //                     fontFamily="Livvic, sans-serif"
        //                     sx={{ color: "#000000" }}
        //                     textAlign={'left'}
        //                 >
        //                    Enhance your digital presence with our <span style={{ color: '#023E8A' }}>web and mobile development</span> services. We design responsive websites and intuitive mobile applications 
        //                    that captivate your audience and elevate your brand, ensuring an engaging user experience across all devices.
        //                 </Typography>
        //                 <Typography
        //                     fontFamily="Livvic, sans-serif"
        //                     sx={{ color: "#000000" }}
        //                     textAlign={'left'}
        //                 >
        //                    By choosing <span style={{ color: '#023E8A' }}>SoftSavvy,</span> you gain access to a dedicated team committed to solving your IT problems with the best possible solutions. 
        //                    Let us guide you through the complexities of the digital landscape and help you achieve your business objectives. </Typography>
        //             </Box>
        //         </Box>
        //         <Box
        //             maxWidth={isMobile ? "100%" : "50%"}
        //             display="flex"
        //             marginLeft={!isMobile ? '2rem' : undefined}
        //         >
        //             <img
        //                 src={man}
        //                 alt="Hero"
        //                 style={{ width: "100%" }}
        //             />
        //         </Box>
        //     </Box>
        // </Box>
        <div className="choose-section">
            <div className="section-gap-130">
                <div className="container w-container">
                    <div className="service-hero-grid-layout">
                        <div className="service-hero-gridbox-left">
                            <div className="section-block">
                                <h2 className="section-title max-width-370">
                                    We have the<span className="section-title-inner-style"> best IT Services</span> to solve your{' '}
                                    <span className="section-title-inner-style">Problems</span>
                                </h2>
                                <div className="servcie-medium-subtitle-text">
                                    Over <strong>25 years</strong> working in IT services, developing
                                    <br />
                                    software, mobile apps for clients all over the world.
                                </div>
                                <div className="section-text">
                                    At SoftSavvy, we offer top-tier IT services designed to address your unique challenges and propel your business forward.
                                </div>
                                <div className="section-text">
                                    Our <span style={{ color: '#023E8A' }}>IT Training </span> programs are designed to keep your skills up-to-date in the fast-evolving tech industry.
                                    We offer courses for all skill levels, equipping individuals and teams with the latest technological knowledge.
                                </div>
                                <div className="section-text">
                                    Enhance your digital presence with our <span style={{ color: '#023E8A' }}>web and mobile development</span> services. We design responsive websites and intuitive mobile applications
                                    that captivate your audience and elevate your brand, ensuring an engaging user experience across all devices.
                                </div>
                                <div className="section-text">
                                    By choosing <span style={{ color: '#023E8A' }}>SoftSavvy,</span> you gain access to a dedicated team committed to solving your IT problems with the best possible solutions.
                                    Let us guide you through the complexities of the digital landscape and help you achieve your business objectives.
                                </div>
                                <a href="#Service-Section" className="section-link margin-top-20 w--current">
                                    Choose your Desired Services
                                </a>
                            </div>
                        </div>
                        <div className="service-hero-gridbox-right">
                            <div className="choose-image-box">
                                <img
                                    src={man}
                                    loading="lazy"
                                    alt="Choose Image"
                                    className="choose-image"
                                />
                                <div className="choose-inner-text-box-1">
                                    <div className="text-block">100% Clients Satisfaction</div>
                                </div>
                                <div className="choose-inner-text-box-2">
                                    <div className="text-block">Get Perfect Solution for your Problems</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}