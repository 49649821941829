import React, { useRef, useState } from 'react';
import { Typography, Box, useMediaQuery, TextField, Button, Snackbar, Alert } from "@mui/material";
import emailjs from '@emailjs/browser';

export default function QuoteForm() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const formRef = useRef();
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_QUOTE_TEMPLATE_ID,
            formRef.current,
            process.env.REACT_APP_USER_ID
        ).then((response) => {
            setSeverity("success");
            setMessage('Quote request submitted successfully! We will get back to you soon.');
            setOpen(true);
            formRef.current.reset();
        }, (err) => {
            setSeverity("error");
            setMessage('Failed to submit quote request. Please try again.');
            setOpen(true);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className="contact-form-section wf-section">
            <div className="section-gap-130">
                <div className="container w-container">
                    <div className="quote-form-wrapper">
                        <div className="quote-upper-text">Get a <span style={{ color: '#0077B6' }}>Quote</span></div>
                        <div className="quote-form-block w-form">
                            <form  ref={formRef} onSubmit={sendEmail} className="quote-form">
                                <div className="contact-input-group">
                                    <input type="text" className="default-input-field w-input" maxLength="256" name="from_name" placeholder="Name*" id="from_name" required />
                                    <input type="email" className="default-input-field w-input" maxLength="256" name="from_email" placeholder="*Email" id="email" required />
                                    <input type="tel" className="default-input-field w-input" maxLength="256" name="from_phone" placeholder="Phone*" id="phone" required />
                                </div>
                                <div className="contact-input-group">
                                    <div className="quote-input-group-item">
                                        <div className="quote-subtitle">Tell us more about the project</div>
                                        <textarea placeholder="*Describe your project here..." maxLength="5000" name="message" className="default-input-field contact-text-area w-input" style={{ width: "100%" }} required></textarea>
                                    </div>
                                </div>
                                <div className="contact-input-group">
                                    <div className="quote-input-group-item">
                                        <div className="quote-subtitle">When should we start?</div>
                                        <input type="text" className="default-input-field w-input" maxLength="256" name="start" placeholder="Type Date" />
                                    </div>
                                    <div className="quote-input-group-item">
                                        <div className="quote-input-group-item">
                                            <div className="quote-subtitle">When would you like it done?</div>
                                            <input type="text" className="default-input-field w-input" maxLength="256" name="end" placeholder="Type Date" />
                                        </div>
                                    </div>
                                    <div className="quote-input-group-item">
                                        <div className="quote-input-group-item">
                                            <div className="quote-subtitle">What's your budget?</div>
                                            <input type="text" className="default-input-field w-input" maxLength="256" name="budget" placeholder="Type your budget*" required />
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" data-wait="Please wait..." value="Submit Now" className="primary-button margin-top-50 w-button" />
                                <Snackbar
                                    open={open}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                                        {message}
                                    </Alert>
                                </Snackbar>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
