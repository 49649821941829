import {
    Typography,
    Box,
    useMediaQuery,
} from "@mui/material";
import { PageHeader } from "../../Component/shared/component/PageHeader";
import NewsLetter from "../../Component/shared/component/NewsLetter";
import QuoteForm from "./ui/quote-form";
import { QuoteHelmet } from "./helmet";

export default function QuoteScreen() {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <div>
            <QuoteHelmet />
            <main className="main-wrapper">
                <PageHeader
                    title={'Get a Quote'}
                    description={`Get a personalized quote tailored to your specific needs and discover how SoftSavvy can drive your business forward with innovative tech solutions.`}
                    location={[
                        { name: "Home", path: "/" },
                        { name: "Get a Quote", path: "" }
                    ]}
                />
                <QuoteForm />
                <NewsLetter />
            </main>
        </div>
    )
}