import React from "react";

export const HowSection = () => {
    return (
        <div className="work-progress">
            <div className="section-gap-bottom-130">
                <div className="container justify-center w-container">
                    <div className="section-justify-center">
                        <div className="section-block max-width-475 text-center">
                            <h2 className="section-title">
                                How <span className="section-title-inner-style">we</span> work
                            </h2>
                            <div className="section-text">
                                Our streamlined process ensures effective collaboration and successful project delivery:
                            </div>
                        </div>
                    </div>
                    <div className="work-progress-block">
                        <div
                            id="w-node-_0e08f737-4d77-aa87-5e85-122aa03eaf97-44adaf1d"
                            data-w-id="0e08f737-4d77-aa87-5e85-122aa03eaf97"
                            className="single-work-progress-item"
                        >
                            <div className="work-progress-icon-block">
                                <img
                                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caa35c0c771a2145cfd_work-proess-1.png"
                                    loading="lazy"
                                    alt="Word Process Icon"
                                    className="work-progress-icon-image"
                                />
                            </div>
                            <div style={{ color: 'rgb(2, 62, 138)' }} className="work-progress-text">
                                Meet the Client
                            </div>
                            <div style={{ opacity: 0.3 }} className="work-precess-number-text">
                                01
                            </div>
                            <div
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                    transformStyle: 'preserve-3d',
                                }}
                                className="work-progress-horizontal-line-bottom"
                            ></div>
                        </div>
                        <div
                            id="w-node-d082fe7e-6878-c4bd-a438-0d4ecb849a69-44adaf1d"
                            data-w-id="d082fe7e-6878-c4bd-a438-0d4ecb849a69"
                            className="single-work-progress-item"
                        >
                            <div className="work-progress-icon-block">
                                <img
                                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caac167287006189515_work-proess-2.png"
                                    loading="lazy"
                                    alt="Word Process Icon"
                                    className="work-progress-icon-image"
                                />
                            </div>
                            <div style={{ color: 'rgb(2, 62, 138)' }} className="work-progress-text">
                                Brainstorming
                            </div>
                            <div style={{ opacity: 0.3 }} className="work-precess-number-text">
                                02
                            </div>
                            <div
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                    transformStyle: 'preserve-3d',
                                }}
                                className="work-progress-horizontal-line-bottom"
                            ></div>
                        </div>
                        <div
                            id="w-node-_20987207-92c8-8d19-4bc2-da1b525a94ca-44adaf1d"
                            data-w-id="20987207-92c8-8d19-4bc2-da1b525a94ca"
                            className="single-work-progress-item"
                        >
                            <div className="work-progress-icon-block">
                                <img
                                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caa80b0d800d1f700ef_work-proess-3.png"
                                    loading="lazy"
                                    alt="Word Process Icon"
                                    className="work-progress-icon-image"
                                />
                            </div>
                            <div style={{ color: 'rgb(2, 62, 138)' }} className="work-progress-text">
                                Find the Solution
                            </div>
                            <div style={{ opacity: 0.3 }} className="work-precess-number-text">
                                03
                            </div>
                            <div
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                    transformStyle: 'preserve-3d',
                                }}
                                className="work-progress-horizontal-line-bottom"
                            ></div>
                        </div>
                        <div
                            id="w-node-_102497cd-b2fb-3c75-cbc5-a0bb488c2521-44adaf1d"
                            data-w-id="102497cd-b2fb-3c75-cbc5-a0bb488c2521"
                            className="single-work-progress-item"
                        >
                            <div className="work-progress-icon-block">
                                <img
                                    src="https://assets.website-files.com/6330105a320e0e0ed5adaf1c/63352caa9fda7ae99fe80120_work-proess-4.png"
                                    loading="lazy"
                                    alt="Word Process Icon"
                                    className="work-progress-icon-image"
                                />
                            </div>
                            <div style={{ color: 'rgb(2, 62, 138)' }} className="work-progress-text">
                                Project Done
                            </div>
                            <div style={{ opacity: 0.3 }} className="work-precess-number-text">
                                04
                            </div>
                            <div
                                style={{
                                    transform: 'translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                    transformStyle: 'preserve-3d',
                                }}
                                className="work-progress-horizontal-line-bottom"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}