import React from "react";
import { Link } from "react-router-dom";

export const PageHeader = ({ title, description, location }) => {
    const leftTitle = title?.split(' ')[0]
    const rightTitle = title?.split(' ').slice(1).join(' ')

    return (
        <div className="breadcrumb-section">
        <div className="breadcrumb-gap">
            <div className="w-container">
                <div className="breadcrumb-block">
                    <div className="breadcrumb-content">
                        <h1 className="breadcrumb-title">{leftTitle} <span style={{ color: '#0077B6' }}>{rightTitle}</span></h1>
                        <p className="breadcrumb-text">{description}</p>
                        <ul role="list" className="breadcrumb-list">
                            {location.map((crumb, index) => (
                                <li key={index} className="breadcrumb-list-item">
                                    {index < location.length - 1 ? (
                                        <>
                                            <Link to={crumb.path} className="breadcrumb-nav-link ">{crumb.name}</Link>
                                            <div className="breadcrumb-separator"></div>
                                        </>
                                    ) : (
                                        <div className="breadcrumb-nav-link-text" style={{ color: '#023E8A' }}>{crumb.name}</div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}