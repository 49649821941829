import {
    useMediaQuery
} from "@mui/material";
import man from "../../../Component/images/about.jpg"

export default function Hero() {
    const isMobile = useMediaQuery("(max-width:960px)");

    return (
        <div className="about-section">
            <div className="section-gap-top-130">
                <div className="container w-container">
                    <div className="about-block">
                        <div className="about-image-block">
                            <img src={man} loading="lazy" alt="About Image" style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }} />
                        </div>
                        <div className="about-content">
                            <h2 className="section-title">Hi <span className="section-title-inner-style">there!</span></h2>
                            <p className="about-subtitle paragraph-medium"> With over 2 years of experience in IT services, SoftSavvy specializes in developing cutting-edge software and mobile applications for clients worldwide.</p>
                            <p> We at  <span style={{ color: '#023E8A' }}>SoftSavvy </span> are passionate about leveraging <span style={{ color: '#023E8A' }}> technology</span> to drive business growth and innovation.
                                Our team of skilled professionals is dedicated to providing tailored solutions that meet the unique needs of each client. Whether you’re a small business looking to enhance your digital presence or a large corporation in need of advanced IT infrastructure, we have the expertise to deliver exceptional results.            
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}