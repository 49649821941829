import React, { useRef, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import emailjs from '@emailjs/browser'

export default function NewsLetter() {
    const formRef = useRef();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const sendSubscription = (e) => {
        e.preventDefault();

        const existingEmail = localStorage.getItem(email);
        if (existingEmail !== null) {
            setMessage('Subscription successful! Thank you for subscribing.');
            setSeverity("success");
            setOpen(true);
            setEmail('');
            return;
        }

        emailjs.sendForm(
            process.env.REACT_APP_NEWSLETTER_SERVICE_ID,
            process.env.REACT_APP_NEWSLETTER_TEMPLATE_ID,
            formRef.current,
            process.env.REACT_APP_NEWSLETTER_USER_ID
        ).then((response) => {
            localStorage.setItem(email, 'subscribed');
            setMessage('Subscription successful! Thank you for subscribing.');
            setSeverity("success");
            setOpen(true);
            setEmail('');
            formRef.current.reset();
        }, (err) => {
            setError('Subscription failed. Please try again.');
            setSeverity("error");
            setOpen(true);
            setEmail('');
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className="newsletter-section">
            <div className="section-gap-bottom-130">
                <div className="container w-container">
                    <div className="newsletter-grid">
                        <div className="newsletter-content">
                            <h2 className="section-title">Subscribe our <span className="section-title-inner-style">Newsletter</span></h2>
                            <div className="section-text">Subscribe our newsletter to stay informed about the latest <span className="newsletter-text-span">updates, offers, and promotions</span>  tailored specifically for business growth and innovation. </div>
                        </div>
                        <div className="newsletter-form">
                            <div className="newsletter-form-block w-form">
                                <form ref={formRef} onSubmit={sendSubscription} className="newsletter-form" aria-label="Email Form">
                                    <input className="newsletter-input-field w-input" maxLength="256" data-name="Email" placeholder="Enter your email here..." type="email" name="from_email" value={email} onChange={handleChange} />
                                    <input type="submit" data-wait="Please wait..." className="primary-button newsletter-button w-button" value="Send now" />
                                </form>
                                <Snackbar
                                    open={open}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                                        {severity === "success" ? message : error}
                                    </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
