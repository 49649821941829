import { PageHeader } from "../../../Component/shared/component/PageHeader";
import NewsLetter from "../../../Component/shared/component/NewsLetter";
import { useServices } from "../../../Entities/Services/model";
import { useParams } from "react-router-dom";
import Hero from "./sections/hero";
import Areas from "./sections/areas";
import How from "./sections/how";
import Benefits from "./sections/benefits";
import { WhyUsSection } from "../../../Component/shared/component/why-softsavvy";
import NotFoundScreen from "../../../Component/NotFound";
import { useEffect, useRef } from "react";

export default function ServiceInfoScreen() {
    const { services } = useServices();
    const { serviceID } = useParams();
    const service = services.find((service) => service.id === serviceID);

    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -10% 0px'
            }
        );

        sectionsRef.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            sectionsRef.current.forEach((section) => {
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    if (!service) return <NotFoundScreen />
    const title = service.title
    return (
        <main className="main-wrapper">
            <div className="tsection" ref={(el) => sectionsRef.current[0] = el}>
                <PageHeader
                    title={service?.title}
                    description={service?.description}
                    location={[
                        { name: "Home", path: "/" },
                        { name: "Services", path: "/services" },
                        { name: `${title}`, path: "" },
                    ]}
                />
                <div className="service-details-section">
                    <div className="section-gap-130">
                        <div className="container w-container">
                            <div className="service-details-block">
                                <Hero serviceID={service.id} />
                                <Areas serviceID={service.id} />
                                <How serviceID={service.id} />
                                <Benefits serviceID={service.id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tsection" ref={(el) => sectionsRef.current[1] = el}>
                <WhyUsSection />
                <NewsLetter />
            </div>
        </main>
    )
}