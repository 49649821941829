

export default function Item({ icon, label, text }) {
    return (
        <li className="choose-list-item">
            <div className="choose-list-image-icon-block">
                <img src={icon} loading="lazy" alt="Choose Icon" className="choose-image-icon" />
            </div>
            <div className="choose-content">
                <div className="choose-title">{label}</div>
                <p className="choose-text">{text}</p>
            </div>
        </li>
    )
}