import {
    Box,
} from "@mui/material";
import contact from "../../Component/images/contact.jpg"
import SocialLinks from "../../Component/shared/ui-lib/social-links";

export default function Hero() {
    const contactInfo = {
        address: '298A, Second Floor, Main Transformer Street, Damagum, Yobe, Nigeria.',
        phone: ['+7 995 032 74 29', '+234 906 811 68 32'],
        email: ['info@softsavvy.ng'],
    };
    return (
        <div className="contact-section">
            <div className="section-gap-130">
                <div className="container w-container">
                    <div className="contact-block">
                        <div className="contact-map-block">
                            <div className="google-map w-widget w-widget-map" data-widget-style="roadmap" data-widget-latlng="40.8135243,-73.9418374" aria-label="" data-enable-scroll="true" role="region" title="" data-enable-touch="true" data-widget-zoom="12" data-widget-tooltip="" style={{ overflow: 'hidden' }}>
                            <img src={contact} alt="contact ud" style={{ width: '110%', height: '100%' }} />
                            </div>
                        </div>
                        <div className="contact-content-block">
                            <div className="section-block">
                                <h2 className="section-title">Get in <span style={{ color: '#0077B6' }}>touch</span></h2>
                                <div className="section-text">
                                    We value your inquiries and feedback. Whether you have a question about our services, need technical support, or want to explore how SoftSavvy can help your business thrive, our team is ready to assist you.
                                    </div>
                            </div>
                            <div className="contact-details-info">
                                <div className="contact-info-title">Contact Info</div>
                                <div className="contact-info-small-title">Address</div>
                                <div className="contact-info-text">{contactInfo.address}</div>
                                <div className="contact-info-small-title">Phone</div>
                                <div className="contact-info-text">{contactInfo.phone.join(' or ')}</div>
                                <div className="contact-info-small-title">Email</div>
                                <div className="contact-info-text">{contactInfo.email.join(' or ')}</div>
                            </div>
                            <Box display={'flex'} gap={'0.5rem'} marginTop={'1.5rem'}>
                                <SocialLinks bgColor={'#E7FBFF'} />
                            </Box></div>
                    </div>
                </div>
            </div>
        </div>
    )
}