import React from 'react'
import values from "../../../Component/images/values.jpg"
import {
    useMediaQuery
} from "@mui/material";

export default function Values() {
    const isMobile = useMediaQuery("(max-width:960px)");
  return (
    <div className="about-section">
            <div className="">
                <div className="container w-container">
                    <div className="about-block">
                        <div className="about-image-block">
                            <img src={values} loading="lazy" alt="About Image" style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }} />
                        </div>
                        <div className="about-content">
                            <h2 className="section-title">Our<span className="section-title-inner-style"> core</span> values</h2>
                           <div className="about-small-title"><span className="text-span-style">Respect for </span> People</div>
                            <p>We believe in treating everyone with dignity and respect. This value guides our interactions with clients, team members, and partners, fostering a collaborative and inclusive environment where every voice is heard and valued.</p>
                            
                            <div className="about-small-title"><span className="text-span-style">Commitment To  </span> Clients</div>
                            <p>Our <span className="text-span-style">clients</span> are at the heart of everything we do. We prioritize understanding their unique needs and challenges, ensuring our solutions are tailored to achieve their specific goals. Building long-term, trust-based relationships with our clients is fundamental to our success.</p>
                            
                            <div className="about-small-title"><span className="text-span-style">Respect for </span> People</div>
                            <p>We take <span className="text-span-style">responsibility</span> for our actions and their impact. Each team member at <span className="text-span-style">SoFSavvy</span> is committed to upholding the highest standards of integrity and excellence, ensuring that we meet our commitments and deliver on our promises. Personal accountability is key to our success and the trust our clients place in us.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
