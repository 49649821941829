import React from 'react'
import CircleButton from './circle-button';
import { Twitter, LinkedIn, Instagram, WhatsApp } from "@mui/icons-material";

export default function SocialLinks({ bgColor }) {
    const socialButtons = [
        { id: 1, platform: "LinkedIn", Icon: LinkedIn, link: "https://www.linkedin.com/company/softsavvy/"},
        { id: 2, platform: "Twitter", Icon: Twitter, link: "https://x.com/softsavvy_"},
        { id: 3, platform: "Instagram", Icon: Instagram, link: "https://instagram.com/softsavvy_"},
        { id: 4, platform: "Whatsapp", Icon: WhatsApp, link: "https://wa.me/79950327429"},
    ];
    return (
        socialButtons.map((social) => (
            <CircleButton
                key={social.id}
                size="medium"
                color={bgColor || "#48CAE4"}
                onClick={() => window.open(social.link, '_blank')}
                child={
                    <social.Icon sx={{ color: "#023E8A", fontSize: 22 }} />
                }
            />
        ))
    )
}