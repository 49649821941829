import { HeroSection } from "./sections/hero";
import { AboutSection } from "./sections/about";
import { ServicesSection } from "./sections/services";
import { HowSection } from "./sections/how-we-work";
import NewsLetter from "../../Component/shared/component/NewsLetter";
import ProjectsSection from "./sections/project";
import { WhyUsSection } from "../../Component/shared/component/why-softsavvy";
import { Box, useMediaQuery } from "@mui/system";
import React, { useEffect, useRef } from 'react';
import BlogSection from "./sections/blog";
import { CompanyStats } from "../../Component/shared/component/CompanyStats";
import { TrustedCompaniesexport } from "../../Component/shared/component/TrustedCompanies";
import { Testimonials } from "../../Component/shared/component/Testimonials";

export default function HomeScreen() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const sectionsRef = useRef([]);
  
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('visible');
              } else {
                entry.target.classList.remove('visible');
              }
            });
          },
          {
            threshold: 0.1, // Adjust threshold for mobile devices
            rootMargin: '0px 0px -10% 0px' // Adjust rootMargin for better performance on mobile
          }
        );
    
        sectionsRef.current.forEach((section) => {
          if (section) {
            observer.observe(section);
          }
        });
    
        return () => {
          sectionsRef.current.forEach((section) => {
            if (section) {
              observer.unobserve(section);
            }
          });
        };
      }, []);
  
    return (
      <main className="main-wrapper">
        <div className="tsection" ref={(el) => sectionsRef.current[0] = el}><HeroSection /></div>
        <div className="tsection" ref={(el) => sectionsRef.current[1] = el}><AboutSection /></div>
        <div className="tsection" ref={(el) => sectionsRef.current[2] = el}><WhyUsSection /></div>
        <div className="tsection" ref={(el) => sectionsRef.current[3] = el}><ServicesSection /></div>
        <div className="tsection" ref={(el) => sectionsRef.current[4] = el}><CompanyStats /></div>
        <div className="tsection" ref={(el) => sectionsRef.current[5] = el}><HowSection /></div>
        <div className="tsection" ref={(el) => sectionsRef.current[6] = el}><NewsLetter /></div>
        {/* <div className="tsection" ref={(el) => sectionsRef.current[6] = el}><Testimonials /></div> */}
        {/* <div className="tsection" ref={(el) => sectionsRef.current[7] = el}><BlogSection /></div> */}
        {/* <div className="tsection" ref={(el) => sectionsRef.current[7] = el}><TrustedCompaniesexport /></div> */}
      </main>
    );
  }